import React, {Component} from 'react';
import {
  ActivityBar,
  ActivityBarFill,
  ActivityContainer, BloodPressureContainer,
  BorderBottom,
  BoxHeader,
  ChartBarContainer,
  PercentageContainer
} from "./styles";
import PropTypes from "prop-types";
import { PercentageIcon } from '../../utils/icons';
// import LazyImage from '../common/LazyImage/LazyImage';
// import { ImageUrl } from '../../utils/constants';

export default class BloodPressure extends Component{

  constructor(props) {
    super(props)
  }
 
  
  render(){
    const{bloodPressureData, marginRight, isStyle, averagePercentage }= this.props;
    return(
      <BloodPressureContainer marginRight = {marginRight}>
        <BoxHeader>
          <div>
            {/* <LazyImage src={ImageUrl + "/images/CompanyDashBoardV2/blood-pressure.png"}/> */}
            {bloodPressureData[0].id === "Diastolic" ? 'Diastolic Blood Pressure' : 'Systolic Blood Pressure' }
          </div>
          <div className="averagePercentage">Avg : {averagePercentage['average_point'] || 0} </div>
        </BoxHeader>
        <BorderBottom/>
        <ChartBarContainer bloodPressure>
          <div className="sliderAverageContainer">
            <div className="sliderContainer">
              {
                bloodPressureData && bloodPressureData.map((item, index) => (
                  <div key={index}>
                    <ActivityContainer  color={'rgba(0, 92, 135, 0.60)'}>
                      <div className="chartTitle">
                        {item.title}
                      </div>
                      <div className={ isStyle ? "chartSubTitle1" : "chartSubTitle"}>
                        [{item.value}]
                      </div>
                      <ActivityBar style={{ display: 'flex', alignItems: 'center' }} barHeight="28px" isRadius="0px" fillColor="rgba(33, 153, 193, 1)" backgroundFill={'rgba(0, 92, 135, 0.05)'}>
                        <ActivityBarFill   isRadius="0px 4px 4px 0px"  isPadding="0px 2px"  width={item.width} fillColor= {item.fillColor}>
                          {/* <PercentageContainer isRight="-30%">{Number(item.width.replace(/%/g,'')) > 5 ? item.width : ''}</PercentageContainer> */}
                          {/* <span>{Number(item.width.replace(/%/g,'')) > 5 ? item.width : ''}</span> */}
                        </ActivityBarFill>
                        <PercentageContainer isMargin={Number(item.width.replace(/%/g, '')) >= 30 ? '0px' : '10px'} left={(item.percentage>90? "90%": item.width)}>
                          {Number(item.width.replace(/%/g, '')) >= 30 ?
                            <div className="icon-container">
                              {PercentageIcon(item.fillColor)}
                              <div style={{ marginLeft: '-38px' }}>{item.width}</div>
                            </div>
                            : item.width}
                        </PercentageContainer>
                      </ActivityBar>
                    </ActivityContainer>
                  </div>
                ))
              }
            </div>
          </div>
        </ChartBarContainer>
      </BloodPressureContainer>
    )
  }
}

BloodPressure.propTypes = {
  bloodPressureData: PropTypes.array,
  marginRight : PropTypes.bool,
  isStyle : PropTypes.bool,
  averagePercentage: PropTypes.array,
};